
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';
import { Eyes, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Breadcrumb, NoData, 
        Eyes, Delete, Attention, CheckOne,
        Pagination
    },
    data(){
        return {
            list: {},
            notification: {
                show: false,
                content: '',
                state: 'error'
            },
            modal: {
                show: false,
                width: 600,
                height: 'auto',
                data: {}
            },
            loading: false
        }
    },
    mounted(){
        this.getList();
    },
    methods: {
        getList(){
            this.$api.xrMessages().then((res: any) => {
                console.log(res)
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDeleteMessage(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.list.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '留言删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        },
        updateMessage(){
            this.loading = true;
            this.$api.xrMessageUpdate(this.modal.data.id).then((res: any) => {
                console.log(res)
                this.loading = false;
                if(res.msg === 'success'){
                    this.notification = {
                        show: true,
                        content: '留言标记为已读',
                        state: 'success'
                    };
                    this.modal.show = false;
                    this.getList();
                } else {
                    this.notification = {
                        show: true,
                        content: '接口报错，请联系技术人员',
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class PageMessage extends Vue{}
