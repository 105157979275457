<template>
    <breadcrumb />
    <div class="page-message">
        <div class="page-container" v-if="list.data && list.data.length > 0">
        
            <div class="message-table">
                <table class="table">
                    <thead>
                        <td width="50">id</td>
                        <td width="200">名字</td>
                        <td width="200">电子邮箱</td>
                        <td width="200">手机号</td>
                        <td>留言内容</td>
                        <td width="80">状态</td>
                        <td width="140">留言时间</td>
                        <td width="140">操作</td>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in list.data" :key="index">
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.content }}</td>
                            <td>{{ item.status ? '已读' : '未读' }}</td>
                            <td>{{ item.created_at }}</td>
                            <td>
                                <div class="operation">
                                    <n-button class="btn" 
                                        type="primary"
                                        @click="modal.show = true; modal.data = item;"
                                    >
                                        <eyes theme="outline" size="16" fill="#fff"/>
                                    </n-button>
                                    <n-popconfirm 
                                        position="bottom right"
                                        content="确定永久删除这条留言吗？"
                                        @confirm="handlerDelete(item.id, index)"
                                    >
                                        <n-button class="btn" type="danger">
                                            <delete theme="outline" size="16" fill="#fff"/>
                                        </n-button>
                                    </n-popconfirm>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <no-data text="暂无留言" v-else></no-data>
        <pagination v-if="list.data && list.data.length > 10" v-model:list="list"></pagination>
    </div>

    <n-modal v-model:show="modal.show" :width="modal.width" :height="modal.height" top="20%">
        <div class="show-message">
            <div class="item">
                <span class="label">id</span>
                <b>{{ modal.data.id }}</b>
            </div>
            <div class="item">
                <span class="label">名字</span>
                <b>{{ modal.data.name }}</b>
            </div>
            <div class="item">
                <span class="label">电子邮箱</span>
                <b>{{ modal.data.email }}</b>
            </div>
            <div class="item">
                <span class="label">手机号</span>
                <b>{{ modal.data.phone }}</b>
            </div>
            <div class="item">
                <span class="label">留言内容</span>
                <b>{{ modal.data.content }}</b>
            </div>
            <div class="item">
                <span class="label">状态</span>
                <b>{{ modal.data.status ? '已读' : '未读' }}</b>
            </div>
            <div class="item">
                <span class="label">留言时间</span>
                <b>{{ modal.data.created_at }}</b>
            </div>
            <div class="btns">
                <n-button class="btn" @click="modal.show = false">关闭</n-button>
                <n-button class="btn" type="primary" @click="updateMessage">已读</n-button>
            </div>
        </div>
    </n-modal>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';
import { Eyes, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Breadcrumb, NoData, 
        Eyes, Delete, Attention, CheckOne,
        Pagination
    },
    data(){
        return {
            list: {},
            notification: {
                show: false,
                content: '',
                state: 'error'
            },
            modal: {
                show: false,
                width: 600,
                height: 'auto',
                data: {}
            },
            loading: false
        }
    },
    mounted(){
        this.getList();
    },
    methods: {
        getList(){
            this.$api.xrMessages().then((res: any) => {
                console.log(res)
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDeleteMessage(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.list.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '留言删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        },
        updateMessage(){
            this.loading = true;
            this.$api.xrMessageUpdate(this.modal.data.id).then((res: any) => {
                console.log(res)
                this.loading = false;
                if(res.msg === 'success'){
                    this.notification = {
                        show: true,
                        content: '留言标记为已读',
                        state: 'success'
                    };
                    this.modal.show = false;
                    this.getList();
                } else {
                    this.notification = {
                        show: true,
                        content: '接口报错，请联系技术人员',
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class PageMessage extends Vue{}
</script>

<style lang="scss" scoped>
.page-message{
    @extend .flex-column;
    margin: 0 15px;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
}

.show-message{
    @extend .flex-column;
    padding: 20px;
    .item{
        @extend .flex-row;
        padding: 10px 0;
        font-size: 14px;
        .label{
            width: 20%;
        }
        b{
            width: 80%;
        }
    }
    .btns{
        @extend .flex-row-center;
        padding: 20px 0 0;
        margin-top: 20px;
        border-top: 1px solid $grey-light;
        .btn{
            width: 30%;
            margin: 10px;
        }
    }
}
</style>